import { createClient } from 'contentful';
import { contentfulLocalesMap } from './constants/contenful-locales-map';
import config from './constants/contentful-config.js';

//import 'dotenv/config';

const {
  CONTENTFUL,
} = config;

const getDefaults = (local) => {
  return {
    locale: local,
  };
};

const getLocalesPerRegion = (region) => {
    return contentfulLocalesMap[region];
};

const generateQuery = (query, local = 'en-US') => ({ ...getDefaults(local), ...query });

const isDevelopment = process.env.NODE_ENV === 'development';

const createConnection = () =>
  createClient({
    accessToken: CONTENTFUL.API_KEY,
    environment: isDevelopment ? CONTENTFUL.DEVELOPMENT_ENVIRONMENT : CONTENTFUL.MASTER_ENVIRONMENT,
    space: CONTENTFUL.SPACE_ID,
  });

export const transformContenfulResponse = (entries) => entries.map(entry => {
    const { sys, fields } = entry.items[0];

    return ({
      body: fields.template.fields.body,
      bodyFallback: fields.template.fields.fallbackBody,
      cta: {
        label: fields.template.fields.cta.fields.ctaLabel,
        url: fields.template.fields.cta.fields.ctaUrl,
      },
      heading: fields.template.fields.heading,
      image: {
        url: `https:${fields.template.fields.image.fields.file.url}`,
        title: fields.template.fields.image.fields.title,
      },
      locale: sys.locale,
      preview: fields.preview,
      quote: {
        content: fields.template.fields.quote.fields.content,
        source: fields.template.fields.quote.fields.source,
      },
      subject: fields.subject,
      footer: {
        address: fields.address,
        copyright: fields.copyright,
        contact: fields.contact,
        privacyPolicyLink: {
          label: fields.privacyPolicyLink.fields.ctaLabel,
          url: fields.privacyPolicyLink.fields.ctaUrl,
        },
        templateType: fields.template.sys.contentType.sys.id,
        termsConditionsLink: {
          label: fields.termsConditionsLink.fields.ctaLabel,
          url: fields.termsConditionsLink.fields.ctaUrl,
        },
      }
    });
  })

export const getEmailTemplateEntriesPerRegion = (region, template) => {
  console.log('🎉 Fetching email content from Contentful');
  const locales = getLocalesPerRegion(region);

  const fetchRegionEntries = locales.map((locale =>
      createConnection().getEntries(
          generateQuery({
          content_type: 'email_message',
          include: 6,
          'fields.id': template,
          }, locale)
      )
  ));

  return Promise.all(fetchRegionEntries);
};

// export const getPostPurchaseSurveyEntriesAllLocales = () => {
//   

//   const fetchAllLocales = LOCALES.map((locale =>
//     createConnection().getEntries(
//       generateQuery({
//         content_type: 'email_message',
//         include: 6,
//         'fields.id': 'post-purchase-survey',
//       }, locale)
//     )
//   ));

//   return Promise.all(fetchAllLocales);
// }
