import React from 'react';

const Header = () => {
  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
      <tbody>
        <tr>
          <td valign="top" align="center">
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center" className="ao_wrapper">
              <tbody>
                <tr>
                  <td valign="top" align="center">
                  {/* <!--[if gte mso 9]>
                  </td>
                  <td valign="top">
                  <![endif]--> */}
                    <table width="90" border="0" cellSpacing="0" cellPadding="0" align="left">
                      <tbody>
                        <tr>
                          <td valign="top" align="left" width="90">
                            <a border="0" href="https://www.aesop.com" target="_blank" rel="noopener noreferrer">
                              <img src="https://hosting.fyleio.com/35578/public/templates/aesop-logo-grey.png" width="90" alt="" className="Header--aesopLogo" />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  {/* <!--[if gte mso 9]>
                  </td>
                  <td valign="top">
                  <![endif]--> */}
                    <table width="30" border="0" cellSpacing="0" cellPadding="0" className="ao_hide" align="left">
                      <tbody>
                        <tr>
                          <td className="ao_hide">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  {/* <!--[if gte mso 9]>
                  </td>   
                  <td valign="top">
                  <![endif]--> */}
                    <table width="130" border="0" cellSpacing="0" cellPadding="0" align="right">
                      <tbody>
                        <tr>
                          <td height="4" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Header;