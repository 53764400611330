import React from 'react';
import PropTypes from 'prop-types';

import Header from '../../header/Header'
import Button from '../../button/BulletproofCTA'

const StandardTemplate = ({
  body,
  bodyFallback,
  heading,
  image,
  preview,
  ctaContent,
  secondaryContent
}) => {

  const {
    body: secondaryBody,
    heading: secondaryHeader,
  } = secondaryContent;

  const bodyCopy = () => {
    if(body) {
      return (
        <div>
          <p>{body}</p>
          {secondaryBody &&
            <p className="StandardTemplate--secondaryBodyCopy">{secondaryBody}</p>
          }
        </div>
      );
    } else if (bodyFallback) {
      return (
        <div>
          {bodyFallback} <a className="StandardTemplate--fallbackLink" href="mailto:Customer.Insight@aesop.com" title="mail to Customer.Insight@aesop.com">Customer.Insight@aesop.com</a>.
        </div>
      );
    }
  };

  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0" bgcolor="#FFFEF3">
      {/* <!-- BODY BACKGROUND COLOUR SPECIFIED ABOVE --> */}
      <tbody>
        <tr>
          <td valign="top" align="center">
            <table width="700" border="0" cellSpacing="0" cellPadding="0" align="center" className="EmailTemplate--section--outerContainer ao_wrapper">
              <tbody>
                <tr>
                  <td align="center" valign="top" className="EmailTemplate--section--outerPadding">
                    <table width="620" border="0" cellSpacing="0" cellPadding="0" className="EmailTemplate--section--innerContainer ao_wrapper">
                      <tbody>
                        {/* <!-- START SECTION - PREHEADER  -->
                        <!-- START SECTION - PREHEADER  --> */}
                        <tr>
                          <td valign="top" align="center">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                              <tbody>
                                <tr>
                                  <td valign="middle" height="5" align="left" className="EmailTemplate--reviewContainer">
                                    <div className="EmailTemplate--reviewContent">
                                      {preview}
                                    </div>
                                    {/* <!-- Insert &zwnj;&nbsp; hack after hidden preview text --> */}
                                    <div className="EmailTemplate--hackAfterReview">
                                    &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {/* <!-- END SECTION - PREHEADER  -->
                        <!-- END SECTION - PREHEADER  --> */}
                        <tr>
                          <td height="20" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td height="20" className="EmailTemplate--emptySpace ao_hide">&nbsp;</td>
                        </tr>
                        {/* <!-- START SECTION - HEADER  -->
                        <!-- START SECTION - HEADER  --> */}
                        <tr>
                          <td valign="top" align="center">
                            <Header />
                          </td>
                        </tr>
                        {/* <!-- END SECTION - HEADER  -->
                        <!-- END SECTION - HEADER  --> */}
                        <tr>
                          <td height="25" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- START SECTION - BLACK SPACER -->
                        <!-- START SECTION - BLACK SPACER --> */}
                        <tr>
                          <td height="1" bgcolor="#CFCDC0" className="EmailTemplate--emptySpace">
                            <img src="http://hosting.fyleio.com/29159/public/spacer.gif" width="1" height="1" alt="" className="StandardTemplate--fullWidthHeroImage" />
                          </td>
                        </tr>
                        {/* <!-- END SECTION - BLACK SPACER -->
                        <!-- END SECTION - BLACK SPACER --> */}
                        <tr>
                          <td height="40" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- ***************************************** -->
                        <!-- START SECTION - TEXT FULL WIDTH - HEADING H2 SUISSE -->
                        <!-- START SECTION - TEXT FULL WIDTH - HEADING H2 SUISSE --> */}
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                              <tbody>
                                <tr>
                                  <td align="left" className="StandardTemplate--mainHeading">
                                    <div id="h2-suisse">
                                    {heading} {secondaryHeader && <span>| {secondaryHeader}</span>}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {/* <!-- END SECTION - TEXT FULL WIDTH - HEADING H2 SUISSE -->
                        <!-- END SECTION - TEXT FULL WIDTH - HEADING H2 SUISSE --> */}
                        <tr>
                          <td height="40" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- ****************************** -->
                        <!-- START SECTION - FULL IMAGE -->
                        <!-- START SECTION - FULL IMAGE --> */}
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="center">
                              <tbody>
                                <tr>
                                  <td valign="top" align="center" className="ao_full_img">
                                    <div id="1hero">
                                      <a href="https://www.aesop.com">
                                        {image && <img src={image.url} alt={image.title} border="0" width="620" />}
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {/* <!-- END SECTION - FULL IMAGE -->
                        <!-- END SECTION - FULL IMAGE --> */}
                        <tr>
                          <td height="40" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- ***************************************** -->
                        <!-- START SECTION - TEXT FULL WIDTH - BODY 16 -->
                        <!-- START SECTION - TEXT FULL WIDTH - BODY 16 --> */}
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                              <tbody>
                                <tr>
                                  <td align="left" className="StandardTemplate--bodyCopyContainer">
                                    <div id="text16">
                                      {bodyCopy()}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        {/* <!-- END SECTION - TEXT FULL WIDTH - BODY 16 -->
                        <!-- END SECTION - TEXT FULL WIDTH - BODY 16 --> */}
                        <tr>
                          <td height="35" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- START SECTION - SECONDARY BUTTON BLACK FILL -->
                        <!-- START SECTION - SECONDARY BUTTON BLACK FILL --> */}
                        <tr>
                          <td align="center" valign="top">
                            {ctaContent && (
                              ctaContent.map((element, i) => (
                              <Button key={i} {...element} />
                            )))}
                          </td>
                        </tr>
                        {/* <!-- END SECTION - SECONDARY BUTTON BLACK FILL -->
                        <!-- END SECTION - SECONDARY BUTTON BLACK FILL --> */}
                        <tr>
                          <td height="80" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

StandardTemplate.propTypes = {
  body: PropTypes.string,
  bodyFallback: PropTypes.string,
  cta: PropTypes.object,
  heading: PropTypes.string,
  image: PropTypes.object,
  preview: PropTypes.string,
  ctaContent: PropTypes.arrayOf(PropTypes.object)
};

export default StandardTemplate;