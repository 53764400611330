import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
  } from "react-router-dom";
import '../src/styles/main.css';

import EmailTemplate from './components-common/email-template/EmailTemplate';

ReactDOM.render(
    <Router>
        <Switch>
            <Route path="/:countryCode/:template" component={EmailTemplate}/>
            <Route path="/">
                <Redirect to="/au/post-purchase-survey" />
            </Route>
        </Switch>
    </Router>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
