import React from 'react';
import PropTypes from 'prop-types';

const Quote = ({ content, source}) => {
  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td valign="top" align="center">
            <table width="700" border="0" cellSpacing="0" cellPadding="0" align="center" className="EmailTemplate--section--outerContainer ao_wrapper">
              <tbody>
                <tr>
                  <td align="center" valign="top" className="EmailTemplate--section--outerPadding">
                    <table width="620" border="0" cellSpacing="0" cellPadding="0" className="EmailTemplate--section--innerContainer ao_wrapper">
                      <tbody>
                        <tr>
                          <td height="50" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        {/* <!-- ****************************** -->
                        <!-- START SECTION - QUOTE  -->
                        <!-- START SECTION - QUOTE --> */}
                        <tr>
                          <td height="20" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <table width="500" border="0" cellSpacing="0" cellPadding="0" className="Quote--contentWrapper ao_wrapper">
                              <tbody>
                                <tr>
                                  <td align="left" valign="top">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                                      <tbody>
                                        <tr>
                                          <td align="left" className="Quote--copy">
                                            <div id="quote">
                                              {content && (
                                                <div>
                                                  {content}
                                                </div>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td height="30" className="EmailTemplate--emptySpace">&nbsp;</td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" align="left">
                              <tbody>
                                <tr>
                                  <td align="left" className="Quote--author">
                                    {source && (
                                      <div id="quote-author">
                                        {source}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td height="30" className="EmailTemplate--emptySpace">&nbsp;</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      {/* <!-- END SECTION - QUOTE -->
                      <!-- END SECTION - QUOTE --> */}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

Quote.propTypes = {
  content: PropTypes.string,
  author: PropTypes.string,
};

export default Quote;