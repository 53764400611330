export const contentfulLocalesMap = {
  au: ['en-AU'],
  at: ['de-AT'],
  be: ['fr-BE', 'en-BE'],
  ca: ['en-CA', 'fr-CA'],
  ch: ['de-CH', 'fr-CH', 'en-CH'],
  de: ['de'],
  dk: ['en-DK'],
  fr: ['fr'],
  hk: ['zh-Hant-HK', 'en-HK'],
  it: ['it'],
  jp: ['ja'],
  kr: ['ko'],
  no: ['en-NO'],
  nz: ['en-NZ'],
  se: ['en-SE'],
  sg: ['en-SG'],
  uk: ['en-GB'],
  us: ['en-US'],
  tw: ['zh-Hant-TW'],
};

export const primaryLanguageSelection = {
  be: 'fr-BE',
  ca: 'en-CA',
  ch: 'de-CH',
  hk: 'zh-Hant-HK'
}

export const secondaryLanguageSelection = {
  ca: 'fr-CA',
  ch: 'fr-CH',
  hk: 'en-HK',
}
