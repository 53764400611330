import React, { useState, useEffect } from 'react';

import StandardTemplate from '../templates/standard/Standard'
import Quote from '../quote/Quote'
import Footer from '../footer/Footer'
import {
  useParams
} from "react-router-dom";

import { contentfulLocalesMap, primaryLanguageSelection, secondaryLanguageSelection } from '../../lib/constants/contenful-locales-map';

import {
  getEmailTemplateEntriesPerRegion,
  transformContenfulResponse,
} from '../../lib/contentful';

function EmailTemplate() {
  let { countryCode, template } = useParams();

  const [mainContent, setMainContent] = useState({ cta: {}, image: {}, quote: {}, footer: {}});
  const [secondaryContent, setSecondaryContent] = useState('');
  const [isError, setisError] = useState(false);
  const [ctaContent, setCtaContent] = useState([]);
  
  useEffect(() => {
    getEmailTemplateEntriesPerRegion(countryCode, template)
    .then(entries => transformContenfulResponse(entries))
    .then(transformedData => {

      if(Object.keys(primaryLanguageSelection).includes(countryCode)) {
        setMainContent(transformedData.find(item => primaryLanguageSelection[countryCode] === item.locale))
      } else {
        setMainContent(transformedData.find(item => contentfulLocalesMap[countryCode].find(locale => locale === item.locale)));
      }
      if(Object.keys(secondaryLanguageSelection).includes(countryCode)) {
        setSecondaryContent(transformedData.find(item => secondaryLanguageSelection[countryCode] === item.locale));
      }

      setCtaContent(transformedData.map(item => item.cta));
    })
    .catch(e => {
      console.log(e);
      const error = {
        body: 'There is an error. Make sure you are typing the proper countryCode and templateType combination or there is content setup in contentful',
      }
      setMainContent(error);
      setisError(true);
    });
  }, [countryCode, template]);
  console.log("ctaContent ", ctaContent)
  return (
    <>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td valign="top" align="center">
              <table width="100%" border="0" cellSpacing="0" cellPadding="0" bgcolor="#EAEADE">
              {/* <!-- FOOTER BACKGROUND COLOUR SPECIFIED ABOVE --> */}
                <tbody>
                  <tr>
                    {mainContent && (
                      <td valign="top" align="center">
                        <StandardTemplate {...mainContent} ctaContent={ctaContent} secondaryContent={secondaryContent} />
                        {!isError && (
                          <div>
                            <Quote {...mainContent.quote} />
                            <Footer {...mainContent.footer} />
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EmailTemplate;